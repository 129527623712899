import { action, observable, makeObservable } from 'mobx';
import { AxiosError } from 'axios';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { Active, Over, UniqueIdentifier } from '@dnd-kit/core';

import _, { set } from 'lodash';

import { v4 as uuid } from 'uuid';

import { reverse } from 'named-urls';

import API from 'utils/api';
import RootStore from 'stores/Root';

import { EntityPropertiesType, FormBuilderType, ProtectorType } from 'utils/api/types';
import routes from 'core/routes';

import { transformUUIDKeysToDescriptions } from './utils';

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

export default class EntityLayoutStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable data?: FormBuilderType;

  // data loaded at first, needed to compare with the current data
  @observable loadedData?: FormBuilderType;

  @observable lastPublished?: string;

  @observable containers: string[] = [];

  @observable items: Items = {};

  @observable contentOfLayoutWasEdited = false;

  @observable formName = '';

  @observable formId = 'new';

  @observable currentEditedQuestion: { sectionId: string; fieldId: string } | undefined = undefined;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);
    makeObservable(this);
  }

  @action.bound
  async loadLayout(moduleName: string, formId: string): Promise<void> {
    this.isLoaded = false;
    this.formId = formId;
    // if the formId is new, we create a new layout
    if (formId === 'new') {
      this.data = {
        type: 'object',
        order: [],
        properties: {},
      };
      this.loadedData = {
        type: 'object',
        order: [],
        properties: {},
      };
      this.lastPublished = undefined;
      // this.containers = [];
      // this.items = {};
      this.transformDataForDisplay();
      this.formName = '';
      this.isLoaded = true;
      return;
    }
    try {
      const {
        data: { form, name, updatedAt },
      } = await this.api.loadEntityForm(moduleName, formId)();
      this.formName = name;
      this.data = (form as unknown) as FormBuilderType;
      this.loadedData = (form as unknown) as FormBuilderType;
      this.lastPublished = updatedAt;
      this.containers = form.order;
      this.transformDataForDisplay();
      const fieldsIds = Object.values(this.items).flat();
      this.store.entityFields.changeFieldsToDisabled(fieldsIds as string[]);
      this.error = undefined;
    } catch (err) {
      const e = err as AxiosError;
      this.error = e;
    } finally {
      this.isLoaded = true;
    }
  }

  @action.bound
  setCurrentEditedQuestion(data: { sectionId: string; fieldId: string } | undefined): void {
    this.currentEditedQuestion = data;
  }

  @action.bound
  updateFormName(name: string): void {
    this.formName = name;
  }

  findItem = (id: string): string | undefined => {
    if (id in this.items) {
      return id;
    }
    return Object.keys(this.items).find((key) => this.items[key].includes(id));
  };

  getNextContainerId = (): string => {
    const value = uuid();
    return `untitledSection${value}`;
  };

  // items
  @action.bound
  addFieldToContainer(activeContainer: string, newContainer: string, id: string): void {
    this.items = {
      ...this.items,
      [activeContainer]: this.items[activeContainer].filter((field) => field !== id),
      [newContainer]: [...this.items[newContainer], id],
    };
  }

  @action.bound
  modifyItemsInContainer(container: string, items: string[]): void {
    // Update the items in the specified container
    this.items = {
      ...this.items,
      [container]: items,
    };
  }

  @action.bound
  moveItemsBetweenContainers(
    activeContainer: string,
    overContainer: string,
    overId: string,
    active: Active,
    over: Over
  ): void {
    // If either the active or over container does not exist, return early
    if (!this.items[activeContainer] || !this.items[overContainer]) return;

    const activeItems = this.items[activeContainer];
    const overItems = this.items[overContainer];
    const activeIndex = activeItems.indexOf(active.id);

    // Ensure the active item is valid
    if (activeIndex === -1 || !active.rect.current.translated) return;

    // Determine the new index for the active item in the over container
    const newIndex =
      overId in this.items
        ? overItems.length
        : overItems.indexOf(overId) + (active.rect.current.translated.top > over.rect.top + over.rect.height ? 1 : 0);

    // Move the active item to the new container
    this.items = {
      ...this.items,
      [activeContainer]: activeItems.filter((item) => item !== active.id),
      [overContainer]: [...overItems.slice(0, newIndex), activeItems[activeIndex], ...overItems.slice(newIndex)],
    };

    // Update properties in the data
    if (!this.data) return;

    const activeContainerProperties = this.data?.properties?.[activeContainer];
    const overContainerProperties = this.data?.properties?.[overContainer];

    // If properties for either container are not found, return early
    if (!activeContainerProperties || !overContainerProperties) return;

    // Update the order of items in the active and over containers directly
    activeContainerProperties.order = activeContainerProperties.order.filter((item) => item !== active.id);
    overContainerProperties.order = [
      ...overContainerProperties.order.slice(0, newIndex),
      active.id as string,
      ...overContainerProperties.order.slice(newIndex),
    ];

    // Move the item properties from the active container to the over container
    const movedItem = activeContainerProperties.properties[active.id];
    this.data.properties[overContainer].properties[active.id] = movedItem;
    delete this.data.properties[activeContainer].properties[active.id];
  }

  @action.bound
  setItems(items: Items): void {
    this.items = items;
  }

  validateSectionUniqueness(): boolean {
    const sectionTitles = this.containers.map((container) => this.getSectionTitleAndDescription(container).title);
    const uniqueSectionTitles = new Set(sectionTitles);
    if (sectionTitles.length !== uniqueSectionTitles.size) {
      this.store.notification.enqueueErrorSnackbar('Section names must be unique');
      return false;
    }
    return true;
  }

  validateEmptySections(): boolean {
    const emptySections = this.containers.filter((container) => this.items[container].length === 0);
    if (emptySections.length > 0) {
      this.store.notification.enqueueErrorSnackbar('Sections cannot be empty');
      return false;
    }
    return true;
  }

  validateFormName(): boolean {
    if (this.formName === '') {
      this.store.notification.enqueueErrorSnackbar('Form name cannot be empty');
      return false;
    }
    return true;
  }

  // SAVING
  @action.bound
  async saveForm(): Promise<void> {
    // check if there is no empty section
    if (!this.validateEmptySections()) {
      return;
    }

    // check if all sections names are unique
    if (!this.validateSectionUniqueness()) {
      return;
    }

    // if the form is new, we create a new layout
    if (!this.validateFormName()) {
      return;
    }

    const transformed = this.transformDataForSave();
    if (this.formId === 'new') {
      try {
        const created = await this.api.createEntityForm('events', { name: this.formName, form: transformed })();
        this.lastPublished = created.data.updatedAt;
        this.contentOfLayoutWasEdited = false;
        this.store.notification.enqueueSuccessSnackbar('Form created successfully');
        this.store.routing.push(
          reverse(routes.dashboard.objectManager.details.formBuilder.details, {
            moduleName: 'events',
            formId: created.data.uuid,
          })
        );
      } catch (err) {
        const e = err as AxiosError;
        this.error = e;
        this.store.notification.enqueueErrorSnackbar(e.response?.data.message || 'Could not create form');
      }
      return;
    }

    try {
      const updated = await this.api.updateEntityForm('events', this.formId, {
        name: this.formName,
        form: transformed,
      })();
      this.contentOfLayoutWasEdited = false;
      this.lastPublished = updated.data.updatedAt;
      this.store.notification.enqueueSuccessSnackbar('Form published successfully');
    } catch (err) {
      const e = err as AxiosError;
      this.error = e;
      this.store.notification.enqueueErrorSnackbar(e.response?.data.message || 'Could not save form');
    }
  }

  @action.bound
  transformDataForSave(): FormBuilderType {
    const result: {
      type: string;
      order: string[];
      required: string[];
      properties: EntityPropertiesType;
    } = {
      type: 'object',
      required: [],
      order: [],
      properties: {},
    };

    const updatedContainers = this.containers.map((containerKey) => {
      if (!this.items[containerKey]) return containerKey;

      const { title } = this.getSectionTitleAndDescription(containerKey);
      const newKey = containerKey.startsWith('untitledSection') ? _.camelCase(title) : containerKey;

      const transformedData = transformUUIDKeysToDescriptions({
        ...this.data?.properties[containerKey],
        order: this.items[containerKey],
      });

      _.set(result.properties, newKey, {
        type: 'object',
        order: transformedData.order,
        title,
        description: this.data?.properties[containerKey]?.description,
        required: [],
        properties: transformedData.properties,
      });

      return newKey;
    });

    result.order = updatedContainers;

    return (result as unknown) as FormBuilderType;
  }

  @action.bound
  transformDataForDisplay(): void {
    if (!this.data) return;
    const result: Items = {};

    this.data.order.forEach((key: string) => {
      result[key] = this.data!.properties[key].order;
    });
    this.items = result;
  }

  // SECTIONS
  @action.bound
  addSection(): string {
    if (!this.data) return '';

    const key = this.getNextContainerId();
    this.containers.push(key);

    const defaultNewSection = {
      type: 'object',
      order: [],
      properties: {},
      required: [],
      title: 'Untitled section',
      description: '',
    };

    this.data.order = this.containers;
    this.data.properties[key] = defaultNewSection;

    this.transformDataForDisplay();
    return key;
  }

  @action.bound
  addQuestionToSection(sectionId: string): void {
    if (!this.data) return;

    const key = uuid();
    this.items = {
      ...this.items,
      [sectionId]: [...this.items[sectionId], key],
    };

    this.data = set({ ...this.data }, `properties.${sectionId}.order`, this.items[sectionId]);

    const defaultNewField = {
      type: 'string',
      description: 'Untitled question',
      protectorType: ProtectorType.ShortText,
    };

    this.data = set({ ...this.data }, `properties.${sectionId}.properties.${key}`, defaultNewField);

    this.setCurrentEditedQuestion({ sectionId, fieldId: key });
  }

  @action.bound
  removeSection(key: string): void {
    this.containers = this.containers.filter((container) => container !== key);
  }

  @action.bound
  getSectionTitleAndDescription(id: string): { title: string; description: string } {
    if (!this.data) return { title: '', description: '' };
    return {
      title: this.data.properties[id].title,
      description: this.data.properties[id].description || '',
    };
  }

  @action.bound
  updateSectionDetails(id: string, title?: string, description?: string): void {
    if (!this.data) return;
    if (title !== undefined) {
      this.data = set({ ...this.data }, `properties.${id}.title`, title);
    }
    if (description !== undefined) {
      this.data = set({ ...this.data }, `properties.${id}.description`, description);
    }
  }

  @action.bound
  updatedFieldTitle(id: string, title?: string): void {
    if (!this.data) return;
    const sectionId = this.findItem(id);
    if (!sectionId) return;
    this.data = set({ ...this.data }, `properties.${sectionId}.properties.${id}.description`, title);
  }

  @action.bound
  resetForm(): void {
    this.data = undefined;
    this.loadedData = undefined;
    this.lastPublished = undefined;
    this.containers = [];
    this.items = {};
    this.formName = '';
    this.currentEditedQuestion = undefined;
    this.contentOfLayoutWasEdited = false;
  }
}
